<template>

  <div class="wrapper" :class="{'nav-open': $sidebar.showSidebar}">
    <notifications></notifications>

    <side-bar>

      <user-menu></user-menu>

      <mobile-menu></mobile-menu>

      <template slot-scope="props" slot="links">
        <sidebar-item :link="{name: $store.getters['languageMixin/getStringFromLanguage']('Dashboard'), icon: 'nc-icon nc-chart-pie-35', path: '/training-sessions/registrations/all'}">
        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions']
                       (['members/index', 'member-types/index'])"
                      :link="{name: $store.getters['languageMixin/getStringFromLanguage']('Members'), icon: 'nc-icon nc-single-02'}">

          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['members/index'])"
                        :link="{name: $store.getters['languageMixin/getStringFromLanguage']('Members'), path: '/members'}">
          </sidebar-item>
<!--          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['members/deleted-members'])"-->
<!--                        :link="{name: $store.getters['languageMixin/getStringFromLanguage']('Deleted Members'), path: '/members/deleted-members'}">-->
<!--          </sidebar-item>-->
          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['member-types/index'])"
                        :link="{name: $store.getters['languageMixin/getStringFromLanguage']('Member Types'), path: '/member-types'}">
          </sidebar-item>
        </sidebar-item>


        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions']
                       (['coupons/index', 'referral-coupons/index'])"
                      :link="{name: $store.getters['languageMixin/getStringFromLanguage']('Coupons'), icon: 'nc-icon nc-money-coins'}">

          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['coupons/index'])"
                        :link="{name: $store.getters['languageMixin/getStringFromLanguage']('Coupons'), path: '/coupons'}">
          </sidebar-item>
          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['referral-coupons/index'])"
                        :link="{name: $store.getters['languageMixin/getStringFromLanguage']('Referral Coupons'), path: '/referral-coupons'}">
          </sidebar-item>
        </sidebar-item>


        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions']
                       (['registrations/index'])"
                      :link="{name: $store.getters['languageMixin/getStringFromLanguage']('Registrations'), icon: 'nc-icon nc-credit-card'}">

          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['registrations/index'])"
                        :link="{name: $store.getters['languageMixin/getStringFromLanguage']('Sessions'), path: '/training-sessions/registrations/session'}">
          </sidebar-item>
          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['registrations/index'])"
                        :link="{name: $store.getters['languageMixin/getStringFromLanguage']('Cycles'), path: '/training-sessions/registrations/cycle'}">
          </sidebar-item>
        </sidebar-item>
        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions']
                       (['training-sessions/index'])"
                      :link="{name: $store.getters['languageMixin/getStringFromLanguage']('Sessions'), icon: 'nc-icon nc-button-play'}">

          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['training-sessions/index'])"
                        :link="{name: $store.getters['languageMixin/getStringFromLanguage']('Incoming Sessions'), path: '/training-sessions/list/incoming'}"
          class="no-transform">
          </sidebar-item>
          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['training-sessions/index'])"
                        :link="{name: $store.getters['languageMixin/getStringFromLanguage']('Past Sessions'), path: '/training-sessions/list/past'}">
          </sidebar-item>
          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['training-sessions/index'])"
                        :link="{name: $store.getters['languageMixin/getStringFromLanguage']('All Sessions'), path: '/training-sessions/list/all'}">
          </sidebar-item>
        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions']
                       (['training-categories/index', 'trainings/index', 'training-cycles/index',])"
                      :link="{name: $store.getters['languageMixin/getStringFromLanguage']('Trainings'), icon: 'nc-icon nc-layers-3'}">

          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['trainings/index'])"
            :link="{name: $store.getters['languageMixin/getStringFromLanguage']('Trainings'), path: '/trainings/list'}"></sidebar-item>
          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['training-categories/index'])"
            :link="{name: $store.getters['languageMixin/getStringFromLanguage']('Training Categories'), path: '/training-categories/list'}"></sidebar-item>
          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['training-cycles/index'])"
            :link="{name: $store.getters['languageMixin/getStringFromLanguage']('Training Cycle'), path: '/training-cycles/list'}"></sidebar-item>

        </sidebar-item>


        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions']
                       (['team-positions/index', 'team/index'])"
                      :link="{name: $store.getters['languageMixin/getStringFromLanguage']('Team'), icon: 'nc-icon nc-badge'}">

          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['team/index'])"
            :link="{name: $store.getters['languageMixin/getStringFromLanguage']('Team'), path: '/team/list'}"></sidebar-item>
          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['team-positions/index'])"
            :link="{name: $store.getters['languageMixin/getStringFromLanguage']('Team Positions'), path: '/team-positions/list'}"></sidebar-item>
<!--          <sidebar-item-->
<!--            v-if="$store.getters['auth/haveOneOfPermissions'](['team-divisions/index'])"-->
<!--            :link="{name: $store.getters['languageMixin/getStringFromLanguage']('Team Divisions', path: '/team-divisions/list'}"></sidebar-item>-->

        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['study-group-team/index'])"
                      :link="{name: $store.getters['languageMixin/getStringFromLanguage']('Study Group'), icon: 'nc-icon nc-ruler-pencil'}">
          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['study-group-team/index'])"
            :link="{name: $store.getters['languageMixin/getStringFromLanguage']('Members'), path: '/study-group-team/list'}"></sidebar-item>

        </sidebar-item>

<!--        Publications-->
        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions']
                       (['publication-categories/index', 'publications/index', 'authors/index'])"
                      :link="{name: $store.getters['languageMixin/getStringFromLanguage']('Publications'), icon: 'nc-icon nc-light-3'}">

          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['publications/index'])"
            :link="{name: $store.getters['languageMixin/getStringFromLanguage']('Publications'), path: '/publications/list'}"></sidebar-item>
          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['publication-categories/index'])"
            :link="{name: $store.getters['languageMixin/getStringFromLanguage']('Categories'), path: '/publication-categories/list'}"></sidebar-item>
          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['authors/index'])"
            :link="{name: $store.getters['languageMixin/getStringFromLanguage']('Authors'), path: '/authors/list'}"></sidebar-item>

        </sidebar-item>

<!--        News-->
        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions']
                       (['news-categories/index', 'news/index'])"
                      :link="{name: $store.getters['languageMixin/getStringFromLanguage']('News'), icon: 'nc-icon nc-paper-2'}">

          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['news/index'])"
            :link="{name: $store.getters['languageMixin/getStringFromLanguage']('News'), path: '/news/list'}"></sidebar-item>
          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['news-categories/index'])"
            :link="{name: $store.getters['languageMixin/getStringFromLanguage']('Categories'), path: '/news-categories/list'}"></sidebar-item>

        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['email-templates/index'])"
                      :link="{name: $store.getters['languageMixin/getStringFromLanguage']('Email Templates'), icon: 'nc-icon nc-puzzle-10', path: '/email-templates'}">
        </sidebar-item>


        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['partners/index'])"
                      :link="{name: $store.getters['languageMixin/getStringFromLanguage']('Partners'), icon: 'nc-icon nc-puzzle-10', path: '/partners'}">
        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['testimonials/index'])"
                      :link="{name: $store.getters['languageMixin/getStringFromLanguage']('Testimonials'), icon: 'nc-icon nc-satisfied', path: '/testimonials'}">
        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions']
                                            (['newsletter/index',
                                             'newsletter-templates/index',])"
                      :link="{name: $store.getters['languageMixin/getStringFromLanguage']('Newsletter'), icon: 'nc-icon nc-email-83'}">

          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['newsletter/index'])"
            :link="{name: $store.getters['languageMixin/getStringFromLanguage']('Subscribers'), path: '/newsletter/list'}"></sidebar-item>
<!--          <sidebar-item-->
<!--            v-if="$store.getters['auth/haveOneOfPermissions'](['newsletter-templates/index'])"-->
<!--            :link="{name: $store.getters['languageMixin/getStringFromLanguage']('Send Newsletter'), path: '/newsletter-templates/list'}"></sidebar-item>-->

        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions']
                                            (['pages/index',
                                             'content-pages/create',
                                             'redirection-pages/create',
                                             'branches/index'])"
                      :link="{name: $store.getters['languageMixin/getStringFromLanguage']('Pages'), icon: 'nc-icon nc-single-copy-04'}">

          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['pages/index'])"
            :link="{name: $store.getters['languageMixin/getStringFromLanguage']('Existing Pages'), path: '/pages/list'}"></sidebar-item>

          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['content-pages/create'])"
                        :link="{name: $store.getters['languageMixin/getStringFromLanguage']('Add Content Page'), path: '/content-pages/create'}"></sidebar-item>

          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['redirection-pages/create'])"
                        :link="{name: $store.getters['languageMixin/getStringFromLanguage']('Add Redirection Page'), path: '/redirection-pages/create'}"></sidebar-item>

          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['branches/index'])"
            :link="{name: $store.getters['languageMixin/getStringFromLanguage']('Branches'), path: '/branches/list'}"></sidebar-item>

        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions']
                       (['panel-groups/index','panels/index'])"
                      :link="{name: $store.getters['languageMixin/getStringFromLanguage']('Panels And Banners'), icon: 'nc-icon nc-album-2'}">

          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['panels/index'])"
            :link="{name: $store.getters['languageMixin/getStringFromLanguage']('Panels'), path: '/panels/list'}"></sidebar-item>

          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['panel-groups/index'])"
            :link="{name: $store.getters['languageMixin/getStringFromLanguage']('Groups'), path: '/panel-groups/list'}"></sidebar-item>

        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['books/index'])"
                      :link="{name: $store.getters['languageMixin/getStringFromLanguage']('Books'), icon: 'nc-icon nc-single-copy-04', path: '/books'}">
        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['settings/index'])"
                      :link="{name: $store.getters['languageMixin/getStringFromLanguage']('Settings'), icon: 'nc-icon nc-settings-gear-64', path: '/settings/list'}">
        </sidebar-item>



        <!--</sidebar-item>-->

        <sidebar-item v-if="$store.getters['auth/checkAccess']('roles/index')
                            // || $store.getters['auth/checkAccess']('permissions/index')
                            || $store.getters['auth/checkAccess']('users/index')
                            || $store.getters['auth/checkAccess']('users/deleted-users')"
                      :link="{name: $store.getters['languageMixin/getStringFromLanguage']('Access Management'), icon: 'nc-icon nc-circle-09'}">

          <sidebar-item v-if="$store.getters['auth/checkAccess']('roles/index')"
                        :link="{name: $store.getters['languageMixin/getStringFromLanguage']('Roles'), path: '/roles/list'}"></sidebar-item>
          <sidebar-item v-if="false"
                        :link="{name: $store.getters['languageMixin/getStringFromLanguage']('Permissions'), path: '/permissions/list'}"></sidebar-item>

          <sidebar-item v-if="$store.getters['auth/checkAccess']('users/index')"
                        :link="{name: $store.getters['languageMixin/getStringFromLanguage']('Users'), path: '/users/list'}"></sidebar-item>

          <sidebar-item v-if="$store.getters['auth/checkAccess']('users/deleted-users')"
                        :link="{name: $store.getters['languageMixin/getStringFromLanguage']('Deleted Users'), path: '/users/DeletedUsersList'}"></sidebar-item>

        </sidebar-item>


<!--        <sidebar-item v-if="$store.getters['auth/checkAccess']('registrations/logs')"-->
<!--                      :link="{name: $store.getters['languageMixin/getStringFromLanguage']('Logs'), icon: 'nc-icon nc-circle-09'}">-->

<!--          <sidebar-item v-if="$store.getters['auth/checkAccess']('registrations/logs')"-->
<!--                        :link="{name: $store.getters['languageMixin/getStringFromLanguage']('Registrations Log'), path: '/training-sessions/registrations-logs'}"></sidebar-item>-->

<!--        </sidebar-item>-->


      </template>

    </side-bar>


    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar"></dashboard-content>

      <content-footer></content-footer>
    </div>

  </div>
</template>
<script>
import TopNavbar from './TopNavbar.vue'
import ContentFooter from './ContentFooter.vue'
import DashboardContent from './Content.vue'
import MobileMenu from './Extra/MobileMenu.vue'
import UserMenu from './Extra/UserMenu.vue'


export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu,
    UserMenu
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false)
      }
    }
  }
}

</script>

<style>
.sidebar .sidebar-wrapper .nav .nav-link p{
  text-transform: lowercase!important;
}
.sidebar .sidebar-wrapper .nav .nav-link p:first-letter{
  text-transform: capitalize !important;
}
.card label{
  text-transform: lowercase !important;
}
.card label:first-letter{
  text-transform: capitalize !important;
}
</style>
