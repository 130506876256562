export default {
    namespaced: true,
    state: {
        languageData: null
      },
      mutations: {
        setLanguageData(state, data) {
          state.languageData = data;
        }
      },
      getters: {
        getStringFromLanguage: (state) => (string) => {
            if (state.languageData) {

                return state.languageData[string.toLowerCase()]? state.languageData[string.toLowerCase()] :string;
            } else {
                return string;
            }
          },
          getLangFile(state){
            return state.languageData;
          }
      },
      actions: {
        setLanguageFile({commit, state}, fileName){
            fetch(fileName)
            .then(response => response.json())
            .then(data => {
                console.log(data)
                commit('setLanguageData', data);
            })
            .catch(error => {
                console.error('Error fetching JSON file:', error);
            });
        }
      }

  }