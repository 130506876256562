import DashboardLayout from "@/pages/layouts/DashboardLayout";

let referralCouponRoutes = {
  path: '/referral-coupons',
  component: DashboardLayout,
  redirect: '/referral-coupons/list',
  children: [
    {
      path: 'list',
      name: 'Referral Coupon List',
      component: () => import(/* webpackChunkName: "referral-coupons" */  '@/pages/coupons/referralCoupons/ReferralCouponList'),
      meta: {
        permissionsCodes: ['referral-coupons/index'],
      }
    },
    {
      path: 'blacklist',
      name: 'Referral Coupon Blacklist',
      component: () => import(/* webpackChunkName: "referral-coupons" */  '@/pages/coupons/referralCoupons/ReferralCouponBlacklist'),
      meta: {
        permissionsCodes: ['referral-coupons/blacklist'],
      }
    }
  ]
}

export default referralCouponRoutes;
