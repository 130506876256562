<template>
  <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">

    <div class="">
      <p class="card-category">{{ $store.getters['languageMixin/getStringFromLanguage']('Showing') }} {{ from + 1 }} to {{ to }} of {{ total }} {{ $store.getters['languageMixin/getStringFromLanguage']('entries') }}</p>
    </div>

    <ul class="pagination" :class="paginationClass">
      <li class="page-item prev-page" :class="{disabled: value === 1}">
        <a class="page-link" aria-label="Previous" @click="prevPage">
          <span>«</span>
        </a>
      </li>
      <li class="page-item" :class="{active: value === item}" v-for="item in range(minPage, maxPage)">
        <a class="page-link" @click="changePage(item)">{{ item }}</a>
      </li>
      <li class="page-item page-pre next-page" :class="{disabled: value === totalPages}">
        <a class="page-link" aria-label="Next" @click="nextPage">
          <span>»</span>
        </a>
      </li>
    </ul>

  </div>
</template>
<script>
export default {
  name: 'nl-pagination',
  props: {
    color: {
      type: String,
      default: '',
      validator: (value) => {
        return ['', 'blue', 'azure', 'green', 'orange', 'red', 'purple'].includes(value)
      }
    },
    pageCount: {
      type: Number,
      default: 0
    },
    perPage: {
      type: Number,
      default: 10
    },
    total: {
      type: Number,
      default: 0
    },
    value: {
      type: Number,
      default: 1
    }
  },
  computed: {
    paginationClass() {
      return `pagination-${this.color}`
    },
    totalPages() {
      if (this.pageCount > 0) return this.pageCount
      if (this.total > 0) {
        return Math.ceil(this.total / this.perPage)
      }
      return 1
    },
    pagesToDisplay() {
      if (this.totalPages > 0 && this.totalPages < this.defaultPagesToDisplay) {
        return this.totalPages
      }
      return this.defaultPagesToDisplay
    },

    minPage() {
      if (this.value >= this.pagesToDisplay) {
        const pagesToAdd = Math.floor(this.pagesToDisplay / 2)
        const newMaxPage = pagesToAdd + this.value
        if (newMaxPage > this.totalPages) {
          return this.totalPages - this.pagesToDisplay + 1
        }
        return this.value - pagesToAdd
      } else {
        return 1
      }
    },
    maxPage() {
      if (this.value >= this.pagesToDisplay) {
        const pagesToAdd = Math.floor(this.pagesToDisplay / 2)
        const newMaxPage = pagesToAdd + this.value
        if (newMaxPage < this.totalPages) {
          return newMaxPage
        } else {
          return this.totalPages
        }
      } else {
        return this.pagesToDisplay
      }
    },
    to () {
      let highBound = this.from + this.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from () {
      return this.perPage * (this.value - 1)
    },

  },
  data() {
    return {
      defaultPagesToDisplay: 5
    }
  },
  methods: {
    range(min, max) {
      let arr = []
      for (let i = min; i <= max; i++) {
        arr.push(i)
      }
      return arr
    },
    changePage(item) {
      this.$emit('input', item)
    },
    nextPage() {
      if (this.value < this.totalPages) {
        this.$emit('input', this.value + 1)
      }
    },
    prevPage() {
      if (this.value > 1) {
        this.$emit('input', this.value - 1)
      }
    }
  }
}
</script>
